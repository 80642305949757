<template>
  <div class="w1092">
    <div class="mybetwrap">
      <ul class="tabs">
        <li class="tab-link" @click="type = 'all'">{{$t('front.gameCategory.all')}}</li>
        <template v-for="item in gameGroupCodes" v-bind:key="item.groupCode" >
          <li class="tab-link"
              :class="{'current': type == item.groupCode}"
              @click="type = item.groupCode"
          >
            <span>{{$i18n.locale !== 'ko' ? item[`groupCodeName${$i18n.locale.toUpperCase()}`] : item.groupCodeName}}</span>
          </li>
        </template>
      </ul>

      <div class="tab-content">
        <div class="datesearch" v-if="commonCode" >
          <select v-model="vendorKey">
            <template v-for="item in commonCode[type]" v-bind:key="item.code">
              <option :value="item.code">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
              </option>
            </template>
          </select>
          <date-filter @search="loadList(1)"
                       @update="onChangeDate"
                       :startDate="searchDate.startDate"
                       :endDate="searchDate.endDate" :isOldYn="true"
                       @setOldYn="setOldYn" :oldYn="oldYn" />
        </div>

        <div class="board">
          <ul class="boardw first">
            <li class="">{{$t('front.board.dates')}}</li>
            <li class="">{{$t('front.board.company')}}</li>
            <li class="">{{$t('front.board.type')}}</li>
            <li class="">{{$t('front.board.gameType')}}</li>
            <!--li class="">{{$t('front.stributor.detailMore')}}</li-->
            <li class="">{{$t('front.bettingStatus.PASS')}}/{{$t('front.bettingStatus.FAIL')}}</li>
            <li class="">{{$t('front.board.betMoney')}}</li>
            <li class="">{{$t('front.board.revenue')}}</li>
            <li class="">{{$t('front.give.endMoney')}}</li>
          </ul>

          <template v-if="betList.length > 0">
            <template v-for="item in betList" v-bind:key="item.betIdx">
              <ul class="boardw">
                <li class="">{{dateFormatAll(item.createdAt)}}</li>
                <li class="">
                  {{$i18n.locale !== 'ko' ? item[`vendorName${$i18n.locale.toUpperCase()}`] : item.vendorName}}
                </li>
                <li class="">{{item.gameType}}</li>
                <li class="">{{item.gameName}}</li>
                <!--li class=""><a class="detailBtn" @click="detailP=!detailP">{{$t('front.stributor.detail')}}</a></li><-- {{item.gameIdx}} -->
                <li :class="{'yec': item.betCalResults == 'PASS'}">
                  {{$t('front.bettingStatus.' + item.betCalResults)}}
                </li>
                <li class="" >{{thousand(item.cash)}}</li>
                <li class="">{{thousand(item.resultCash)}}</li>
                <li class="">{{thousand(item.afterCash)}}</li>
              </ul>
            </template>
          </template>
          <template v-else>
            <ul class="boardw">
              <li class="">{{$t('front.board.noBet')}}</li>
            </ul>
          </template>
          <div class="detailPop" v-if="detailP">
            <h4>{{$t('front.mypage.betListDetail')}}<a class="close" @click="detailP=!detailP"><img src="@/assets/img/bkclose.png" /></a></h4>
            <ul class="con">
              <li>
                <span>내역1</span>
                <span>10원</span>
              </li>
              <li>
                <span>내역2</span>
                <span>100원</span>
              </li>
              <li>
                <span>내역3</span>
                <span>1000원</span>
              </li>
              <li>
                <span>내역4</span>
                <span>10000원</span>
              </li>
              <li>
                <span>내역5</span>
                <span>100000원</span>
              </li>
            </ul>
          </div>
        </div>

        <pagination v-if="pageInfo"
                    :pageNum="pageInfo.page"
                    :pageSize="pageInfo.count_per_list"
                    :totalCount="pageInfo.tatal_list_count"
                    @goToPage="pageChange"
        />
      </div>
    </div>

  </div>
</template>

<script>

import Pagination from '@/components/ui/Pagination'
import DateFilter from '@/components/ui/DateFilter'
import { GAME_CATEGORY } from '@/libs/constants'
import { mapState } from 'vuex'

export default {
  name: 'bettingList',
  components: {
    Pagination,
    DateFilter
  },
  data () {
    return {
      type: null,
      groupCode: this.type,
      vendorKey: '',
      betList: [],
      gameList: GAME_CATEGORY,
      detailP: false,
      oldYn: 'N'
    }
  },
  computed: {
    ...mapState([
      'gameGroupCodes'
    ])
  },
  created () {
    this.type = this.gameGroupCodes[0].groupCode
    if (this.commonCode) {
      this.defaultValueSetting()
    }
  },
  watch: {
    type (nVal, oVal) {
      // this.onBettingList(this.pageInfo.page, this.gameType)
      this.defaultValueSetting()
      this.loadList()
    },
    commonCode () {
      if (this.commonCode) {
        this.defaultValueSetting()
        this.loadList()
      }
    },
    vendorKey () {
      this.loadList()
    }
  },
  methods: {
    setOldYn (data) {
      this.oldYn = data
    },
    pageChange (page) {
      this.pageInfo.page = page
      this.loadList(page)
    },

    defaultValueSetting () {
      for (const item in this.commonCode[this.type]) {
        const code = this.commonCode[this.type][item].code
        if (code || code === 0) {
          this.vendorKey = code
          break
        }
      }
    },
    async loadList (page) {
      console.log('load------------')
      this.betList = await this.onBettingList(page, this.vendorKey, this.pageType, this.oldYn)
    }
  }
}
</script>
<style scoped>
.boardpage {margin-top: 30px;}
.datesearch .datesearch {margin: 0;}
.boardw li {word-break: break-word}

.detailPop {min-width: 320px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, 0);background: #fff;border: 4px solid #1c594e;padding: 25px;border-radius: 10px;}
.detailPop h4 {display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #ccc;padding-bottom: 20px;margin-bottom: 20px;}
.detailPop a.close img {height: 18px;vertical-align: middle;}
.detailPop .con li {margin-top: 20px;display: flex;align-items: center;justify-content: space-between;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
